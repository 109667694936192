import Rails from "@rails/ujs";
try { Rails.start() } catch {}
window.Rails = Rails

import $ from "jquery";
import DataTable from 'datatables.net-dt';

import "../controllers";

$(document).ready(function() {
  window.addEventListener("DOMContentLoaded", () => {
    // hide alerts after 5 seconds
    setTimeout(function() { $('.alert').fadeOut('slow') }, 5000);
  })

  let table = new DataTable('.js-data-table', {
    "paging":   false,
    "searching": false
  });
})
